
<template>
  <footer class="footer">
    <div class="footer-wrap">
      <div v-if="isDesktop" class="qrcode">
        <div class="qrcode-box">
            <img src="https://static.ppkao.com/public/wxqrcode/pptiku.jpg" alt="扫码联系"/>
            <span>扫码联系在线客服<br/>反馈使用问题</span>
          </div>
        <div class="qrcode-box">
          <img src="https://static.ppkao.com/www/images/zantiku.jpg" :placeholder="120"  alt="扫码练习"/>
          <span>扫码使用找答案小程序<br/>手机搜题/刷题/上网课</span>
        </div>
      </div>
      <div :class="{copyright: isDesktop, 'mobile-copyright': !isDesktop}">
        <p>版权所有©考试资料网(ppkao.com) 长沙求知信息技术有限公司 All Rights Reserved </p>
        <p>
          <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010202000353" title="湘公网安备 43010202000353号" target="_blank">
          <img src="https://static.ppkao.com/public/image/ga.png"  >
          湘公网安备 43010202000353号</a>
          <a href="https://beian.miit.gov.cn/" title="信息产业部ICP备案" target="_blank">备案号: 湘ICP备14005140号-2</a>
        </p>
        <p>
          经营许可证号 : 湘B2-20140064
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
const { isDesktop } = useDevice();
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: var(--footer-h);
  margin-top: var(--footer-mt);
  background-color: var(--card-bg-color);
  img {
    opacity: var(--bg-opacity);
  }
  &-wrap {
    width: var(--footer-w);
    height: auto;
    margin: 0 auto;
    @include flex-bet-center;
    padding: var(--h3-mt) 0;
    .qrcode {
      @include flex-y-center;
      &-box {
        @include flex-col-center;
        margin-right: 70px;
        img {
          width: 120px;
          margin-bottom: 6px;
        }
        span {
          @include font(var(--title-color));
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
  .mobile-copyright {
    width: 100%;
    padding: 12px;
    p {
      line-height: 20px;
      margin-bottom: 8px;
      text-align: center;
    }
    p:nth-child(3) img {
      margin-right: 20px;
      margin-bottom: 6px;
    }
  }
  .copyright {
    @include font(var(--title-color));
    @include flex-col;

    p:nth-child(2) {
      margin: 26px 0;
      a {
        margin-right: 20px;
      }
    }
    p:nth-child(3) {
      @include flex-bet-center;
    }
  }
}
</style>
